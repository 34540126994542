<template>
    <div>
        <List>
            <router-link to="/dashboard/">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <DashboardIcon width="24" height="24" />
                        <div>{{ $t('overview') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link to="/dashboard/assets">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <TripMultipleDestinationsIcon width="24" height="24" />
                        <div>{{ $t('shared.assets') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasConstructionProjectAccess"
                to="/dashboard/construction-projects"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <ExcavatorIcon width="24" height="24" />
                        <div>{{ $t('constructionProjects') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link
                v-if="hasLocationHistoryAccess"
                to="/dashboard/location-history"
            >
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <TimeClockFileSearch width="24" height="24" />
                        <div>{{ $t('router.locationHistoryChart') }}</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isSuperuser" to="/message-stream">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <MonitorHeartRateIcon width="24" height="24" />
                        <div>Message Stream</div>
                    </div>
                </ListItem>
            </router-link>

            <router-link v-if="isStaff" to="/dashboard/settings">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <SettingsSliderIcon width="24" height="24" />
                        <div>{{ $t('settings') }}</div>
                    </div>
                </ListItem>
            </router-link>
        </List>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DashboardIcon from '@/components/icons/DashboardIcon'
import ExcavatorIcon from '@/components/icons/ExcavatorIcon'
import List from '@/components/List'
import ListItem from '@/components/ListItem'
import MonitorHeartRateIcon from '@/components/icons/MonitorHeartRateIcon'
import SettingsSliderIcon from '@/components/icons/SettingsSliderIcon'
import TimeClockFileSearch from '@/components/icons/TimeClockFileSearch'
import TripMultipleDestinationsIcon from '@/components/icons/TripMultipleDestinationsIcon'

export default {
    name: 'DashboardMenu',
    components: {
        DashboardIcon,
        ExcavatorIcon,
        List,
        ListItem,
        MonitorHeartRateIcon,
        SettingsSliderIcon,
        TimeClockFileSearch,
        TripMultipleDestinationsIcon,
    },
    computed: {
        ...mapGetters('authentication', [
            'hasLocationHistoryAccess',
            'hasConstructionProjectAccess',
            'isAdminOrStaff',
            'isStaff',
            'isSuperuser',
        ]),
    },
}
</script>

<i18n>
{
    "en": {
        "constructionProjects": "Construction projects",
        "locations": "Locations",
        "overview": "Overview",
        "settings": "Settings"
    },
    "de": {
        "constructionProjects": "Bauaufträge",
        "locations": "Standorte",
        "overview": "Übersicht",
        "settings": "Einstellungen"
    },
    "it": {
        "constructionProjects": "Construction projects",
        "locations": "Locatione",
        "overview": "Sommario",
        "settings": "Configurazione"
    }
}
</i18n>

<style lang="scss" scoped>
a {
    &:first-child {
        &.router-link-exact-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }

    &:not(:first-child) {
        &.router-link-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }
}
</style>
